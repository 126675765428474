// import
import Menu from "./Menu"; 
import Relatorios from "./Relatorios"; 
import Dashboard from "./Dashboard"; 
import Form from "./Form"; 

//Vars
var menu,relatorios,dashboard,form;

//Methods
function init () {	
	menu = new Menu();

	if ( $(".relatorios").length > 0 ) {
		relatorios = new Relatorios(); 
	}

	if ( $(".dashboard").length > 0 ){
		dashboard = new Dashboard(); 
	}

	if ( $("form").length > 0 ){
		form = new Form(); 
	}

	if ( $("#multiselect").length > 0 ){
		$('#multiselect').multiselect();
	}
}

//Initialization
$(document).ready( init );
