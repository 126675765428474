class Form {
	constructor() {		
		$('form').submit(function(elm){
			var alert = $('.error-alert');
			var submit = true;
			var inputs = $('.required');

			alert.hide();
	
			$.each(inputs, function(key, elm){
				if(!$(elm).val()){
					submit = false;
					$(elm).prev('label').addClass('error');
					alert.show();
				}
			});
	
			if(!submit)
				return false;
		});
	}
	handlerPlatform(event) {}
	handlerGrowth(event) {}
	onResize(event) {}
	slideshowBefore(event, optionHash, outgoingSlideEl, incomingSlideEl, forwardFlag) {}
}

export default Form;
