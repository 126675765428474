class Menu {
	constructor() {
		$('.sidebar-collapse').on('click', this.handlerButton);
		$('.sidebar-close').on('click', this.handlerButton);
	}
	handlerButton(event) {
		if($(this).hasClass('sidebar-close')){
			$('.side,.overlay-side,.block-user').hide();
		}
		
		if($(this).hasClass('sidebar-collapse')){
			if($(this).hasClass('sidebar-mobile')){
				// mobile
				$('.side,.overlay-side,.block-user').show();
			} else {
				// desktop
				$('.logo').toggle();
				$('.navsidebar').toggle();

				if($('.side').hasClass('side-hide'))
			        $('.side').removeClass('side-hide');
			    else
			        $('.side').addClass('side-hide');

			    if($('.section').hasClass('side-hide'))
			        $('.section').removeClass('side-hide');
			    else
			        $('.section').addClass('side-hide');
			}
		}
	}
	handlerPlatform(event) {}
	handlerGrowth(event) {}
	onResize(event) {}
	slideshowBefore(event, optionHash, outgoingSlideEl, incomingSlideEl, forwardFlag) {}
}

export default Menu;
