class Relatorios {
	constructor() {
		/* Generate random times between two dates */
	    var amount = 75;

	    /* Create timeseries */
	    timeseries('timeseries', this.getData(new Date(2014, 1, 1), new Date(2014, 1, 2), amount), false);
	    timeseries('timeseries2', this.getData(new Date(2015, 1, 1), new Date(2015, 1, 2), amount), false);
	    timeseries('timeseries3', this.getData(new Date(2014, 1, 1), new Date(2014, 1, 2), amount), false);
	    timeseries('timeseries4', this.getData(new Date(2015, 1, 1), new Date(2015, 1, 2), amount), false);

	    this.barChart('.bar-chart');
	    this.barChart('.bar-chart2');
	}
	barChart(local) {
		var margin = {top: 20, right: 40, bottom: 20, left: 40},
		    width = 1200,
		    height = 300 - margin.top - margin.bottom;

		// Parse the date / time
		var	parseDate = d3.time.format("%Y-%m").parse;

		var x = d3.scale.ordinal().rangeRoundBands([0, width], .05);

		var y = d3.scale.linear().range([height, 0]);

		var xAxis = d3.svg.axis()
		    .scale(x)
		    .orient("bottom")
		    .tickFormat(d3.time.format("%m/%Y"));

		var yAxis = d3.svg.axis()
		    .scale(y)
		    .orient("left")
		    .ticks(10);

		var svg = d3.select(local).append("svg")
		    .attr("width", width + margin.left + margin.right)
		    .attr("height", height + margin.top + margin.bottom)
		  .append("g")
		    .attr("transform", 
		          "translate(" + margin.left + "," + margin.top + ")");

		d3.csv("uploads/bar-data.csv", function(error, data) {

		    data.forEach(function(d) {
		        d.date = parseDate(d.date);
		        d.value = +d.value;
		    });
			
		  x.domain(data.map(function(d) { return d.date; }));
		  y.domain([0, d3.max(data, function(d) { return d.value; })]);

		  svg.append("g")
		      .attr("class", "x axis")
		      .attr("transform", "translate(0," + height + ")")
		      .call(xAxis)
		    .selectAll("text")
		      .style("text-anchor", "end")
		      .attr("dx", "-.8em")
		      .attr("dy", "-.55em")
		      .attr("transform", "rotate(-90)" );

		  svg.append("g")
		      .attr("class", "y axis")
		      .call(yAxis)
		    .append("text")
		      .attr("transform", "rotate(-90)")
		      .attr("y", 6)
		      .attr("dy", ".71em")
		      .style("text-anchor", "end")
		      .text("Value (R$)");

		  svg.selectAll("bar")
		      .data(data)
		    .enter().append("rect")
		      .style("fill", "steelblue")
		      .attr("x", function(d) { return x(d.date); })
		      .attr("width", x.rangeBand())
		      .attr("y", function(d) { return y(d.value); })
		      .attr("height", function(d) { return height - y(d.value); });

		});
	}
	getData(start, end, amount) {
        var data = [];
        var i = 0;
        for (i = 0; i < amount; i++) {
            data.push({
                'value': (this.randomDate(start, end))
            })
        }
        return data;
	}
	randomDate(start, end) {
        return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime())).valueOf();
    }
	handlerPlatform(event) {}
	handlerGrowth(event) {}
	onResize(event) {}
	slideshowBefore(event, optionHash, outgoingSlideEl, incomingSlideEl, forwardFlag) {}
}

export default Relatorios;
